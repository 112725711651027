import React, { useState, useEffect } from "react";
import { searchResource } from "../../services/apiService";
import { FormControl, InputLabel, Select, MenuItem, Grid, IconButton, Box } from "@mui/material";
import QRScanner from "../qrscanner/QRScanner";
import MultilineText from "../../helpers/MultilineText";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useMessage } from "../../context/MessageContext";
import { useTranslation } from "react-i18next";

const ResourceLocationSelect = ({ selectedResource, onChange, scannerEnabled }) => {
  const { t } = useTranslation();
  const { setMessage } = useMessage();

  const [resources, setResources] = useState(null);

  const fetchData = () => {
    setMessage("");
    setResources(null);

    searchResource({}, { valid: true, ordered: true })
      .then((response) => {
        setResources(response);
      })
      .catch((error) => {
        setResources([]);

        setMessage(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderLoading = () => (
    <MenuItem disabled>
      <CircularProgress size={24} />
      {t("loading")}
    </MenuItem>
  );

  const renderResources = () =>
    resources.map((resource) => (
      <MenuItem key={resource.resource_id} value={resource.resource_id} data-itemname={resource.resource_name}>
        {resource.resource_name}
      </MenuItem>
    ));

  const renderNoData = () => <MenuItem disabled>{t("nodata")}</MenuItem>;

  return (
    <>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <MultilineText text={t("location-select-title")} />
      </Grid>
      <Grid item justifyContent="center" alignItems="center">
        {scannerEnabled && (
          <QRScanner
            setResult={async (result) => {
              const resources = await searchResource({ qrcode: result }, { valid: true });
              if (resources && resources.length === 1) {
                const resource = resources[0];
                if (resource.resource_id && selectedResource?.id !== resource.resource_id) {
                  localStorage.setItem(
                    "kioskLocation",
                    JSON.stringify({
                      id: resource.resource_id,
                      name: resource.resource_name,
                      location: resource.resource_location,
                    })
                  );

                  onChange({
                    target: {
                      value: resource.resource_id,
                      name: resource.resource_name,
                      location: resource.resource_location,
                    },
                  });
                }
              } else {
                setMessage({ text: t("nodata"), type: "error" });
              }
            }}
          />
        )}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl fullWidth style={{ marginTop: "10px", minWidth: "300px" }}>
            <InputLabel id="resource-location-select-label">{t("location")}</InputLabel>
            <Select
              labelId="resource-location-select-label"
              id="resource-location-select"
              value={resources === null || resources.length === 0 ? "" : selectedResource?.id || ""}
              label={t("location")}
              onChange={(event) => {
                localStorage.setItem(
                  "kioskLocation",
                  JSON.stringify({
                    id: event.target.value,
                    name: resources.find((resource) => resource.resource_id == event.target.value)?.resource_name,
                    location: resources.find((resource) => resource.resource_id == event.target.value)
                      ?.resource_location,
                  })
                );

                onChange({
                  target: {
                    value: event.target.value,
                    name: resources.find((resource) => resource.resource_id == event.target.value)?.resource_name,
                    location: resources.find((resource) => resource.resource_id == event.target.value)
                      ?.resource_location,
                  },
                });
              }}
            >
              {resources === null ? renderLoading() : resources.length > 0 ? renderResources() : renderNoData()}
            </Select>
          </FormControl>

          {resources !== null && resources.length === 0 && (
            <IconButton onClick={fetchData} aria-label="refresh">
              <RefreshIcon />
            </IconButton>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default ResourceLocationSelect;
