import React from "react";
import { version_code } from "../version";
import { Container, Typography, Box, Link } from "@mui/material";
import { isKiosk } from "../functions";

export default function Header() { 
  return (
    <Box sx={{ backgroundColor: "primary.dark", color: "white", py: 0 }}>
      <Container maxWidth={"xl"} style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="subtitle1" align="left" sx={{ fontSize: "smaller", lineHeight: "1.2" }}>
          {`KVIKCHECK - Seamless Access, Effortless Management (${version_code?.toLowerCase()})`}
        </Typography>

        <Typography
          variant="subtitle1"
          align="right"
          color="textSecondary"
          sx={{
            textAlign: "right",
            fontSize: "smaller",
            lineHeight: "1.2",
            ml: 1,
            color: "white",
          }}
        >
          {isKiosk() ? (
            <>KVIKEST - The Digital Early Bird Booking App</>
          ) : (
            <Link
              href="https://kvikest.com"
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white" }}
            >
              KVIKEST - The Digital Early Bird Booking App
            </Link>
          )}
        </Typography>
      </Container>
    </Box>
  );
}
