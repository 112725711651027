// src/components/Home.js
import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Container, Grid, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import ProfileData from "../features/profile/ProfileData";
import AppMenu from "../components/AppMenu";
import ResourceLocationSelect from "../features/location/ResourceLocationSelect";
import ResourceUse from "../features/use/ResourceUse";
import CheckBackend from "../components/CheckBackend";
import CreateUserForm from "../features/registration/CreateUserForm";
import { useHost } from "../context/HostContext";
import { useMessage } from "../context/MessageContext";
import { useTranslation } from "react-i18next";
import QRCodePrinter from "../features/registration/QRCodePrinter";
import ExcelToJson from "../features/manager/ExcelToJson";
import SearchUserForm from "../features/registration/SearchUserForm";
import CheckVersion from "../components/CheckVersion";
import UpdateMonitor from "../features/manager/UpdateMonitor";
import SearchTransactionForm from "../features/registration/SearchTransactionForm";
import EventSettings from "../features/manager/EventSettings";
import { isKiosk, isKioskRole } from "../functions";

const Home = () => {
  const { t } = useTranslation();

  const { host } = useHost();

  const { setMessage } = useMessage();
  const { messageText, messageType } = useMessage();

  // const [processing, setProcessing] = useState(false);
  const processing = false;

  const [menuItem, setMenuItem] = useState("");

  const [selectedResource, setSelectedResource] = useState(null);

  const handleLocationChange = (event) => {
    setSelectedResource({ id: event.target.value, name: event.target.name, location: event.target.location });

    setMenuItem("resource-usage");
  };

  useEffect(() => {
    if (isKiosk()) {
      document.body.classList.add("kiosk");
    } else {
      document.body.classList.remove("kiosk");
    }

    return () => {
      document.body.classList.remove("kiosk");
    };
  }, []);

  useEffect(() => {
    setMessage("");

    if (menuItem === "") {
      if (localStorage.getItem("kioskLocation")) {
        setSelectedResource(JSON.parse(localStorage.getItem("kioskLocation")));
      }
      setMenuItem(localStorage.getItem("kiosk"));
    }

    switch (menuItem) {
      case "resource-location":
        setSelectedResource(null);

        break;

      case "resource-usage":
        if (selectedResource?.id) {
          setMessage("");
        } else {
          setMessage({ text: t("noResource"), type: "error" });

          if (isKioskRole(host)) {
            setMenuItem("resource-location");
          }
        }
        break;

      default:
        break;
    }
  }, [menuItem]);

  const handleMenuItemClick = (menuItem) => {
    setMenuItem(menuItem);
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters style={{ display: "flex", justifyContent: "space-between", minHeight: "56px" }}>
            <AppMenu onMenuItemClick={handleMenuItemClick} />

            <Grid container flexDirection={"column"} alignItems={"center"}>
              <Typography variant="h6" style={{ lineHeight: 1.1, textAlign: "center" }}>
                {host?.eventname} - {host?.eventlocation}
              </Typography>
              {isKioskRole(host) ? (
                <></>
              ) : (
                <Typography variant="caption" style={{ fontSize: "smaller", fontVariant: "small-caps" }}>
                  {host?.name}
                </Typography>
              )}
              
              {!isKioskRole(host) && menuItem && (
                <Typography variant="subtitle1" sx={{ textAlign: "center", fontSize: "smaller", lineHeight: "1.1" }}>
                  {t("menu-" + menuItem)}
                </Typography>
              )}
            </Grid>
            {!isKioskRole(host) && <CheckVersion />}
            <CheckBackend noClick={isKioskRole(host)} />
          </Toolbar>
        </Container>
      </AppBar>

      <Container maxWidth="xl">
        {messageText && (
          <Alert severity={messageType} style={{ marginTop: "10px" }}>
            {messageText}
          </Alert>
        )}

        {!processing && (
          <Grid container justifyContent="center" alignItems="center" style={{ marginTop: "10px" }}>
            {menuItem === "resource-location" && (
              <ResourceLocationSelect
                selectedResource={selectedResource}
                onChange={(e) => {
                  handleLocationChange(e);
                }}
              />
            )}

            {!menuItem && (
              <Typography variant="h5" component="h1" gutterBottom>
                {t("menu-not-selected")}
              </Typography>
            )}

            {menuItem === "resource-usage" && <ResourceUse selectedResource={selectedResource} />}

            {menuItem === "profile" && <ProfileData />}

            {menuItem === "resource-modify" && <ProfileData isEditAllowed={true} allResources={true} />}

            {menuItem === "registration-create" && <CreateUserForm />}

            {menuItem === "registration-search" && <SearchUserForm />}

            {menuItem === "registration-printer-setup" && (
              <QRCodePrinter
                setup={true}
                name={t("printer-setup-test-name")}
                company={t("printer-setup-test-company")}
                qrcode={t("printer-setup-test-qrcode")}
              />
            )}

            {menuItem === "manager-settings" && <EventSettings />}

            {menuItem === "manager-search" && <SearchUserForm roletype={["host", "register", "manager", "zerohost", "checkin", "checkout"]} />}

            {menuItem === "import-table" && <ExcelToJson />}

            {menuItem === "user-monitor" && <UpdateMonitor tableName={"users"} />}

            {menuItem === "transaction-monitor" && <UpdateMonitor tableName={"resource_transactions"} />}

            {menuItem === "transaction-search" && <SearchTransactionForm />}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Home;
