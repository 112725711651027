import React, { useEffect } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom"; // Changed to HashRouter
import Login from "./features/auth/Login";
import Home from "./pages/Home";
import ProtectedRoute from "./features/auth/ProtectedRoute";
import ContextProviders from "./context/ContextProviders";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import baseTheme from "./themes/baseTheme";
import ScrollToTop from "./helpers/ScrollToTop";
//import hostTheme from "./themes/hostTheme";
//import registerTheme from "./themes/registerTheme";
import { HostProvider } from "./context/HostContext";

import "./App.css";
import { isKiosk } from "./functions";

const mode = "light";
const theme = baseTheme(mode);

function App() {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";

    if (isProduction) {
      // Disable context menu
      document.addEventListener("contextmenu", function (e) {
        e.preventDefault();
      });

      // Disable text selection
      document.body.style.userSelect = "none";
      
      /*    
      // Disable pull-down to refresh 
      document.documentElement.style.overscrollBehaviorY = 'contain !important';
      document.body.style.overscrollBehaviorY = 'contain !important';
      */
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // This makes the main container at least as tall as the viewport
        }}
      >
        <HostProvider>
          <Header />
        </HostProvider>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
          }}
        >
          <Router>
            <ContextProviders>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<ProtectedRoute component={Home} />} />
              </Routes>
            </ContextProviders>
          </Router>
        </Box>

        <HostProvider>
          <Footer />
        </HostProvider>

        {!isKiosk() && <ScrollToTop />}
      </Box>
    </ThemeProvider>
  );
}

export default App;
