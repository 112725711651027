import React, { useState, useEffect } from "react";
import { setUserResourceQuantity, searchUser, getUserMissingResources } from "../../services/apiService";
import { Box, Typography, Grid, Button, Alert, CardActions } from "@mui/material";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import QRScanner from "../qrscanner/QRScanner";
import { useTranslation } from "react-i18next";
import { useMessage } from "../../context/MessageContext";
import MultilineText from "../../helpers/MultilineText";
import EditableQuantity from "./EditableQuantity";
import { useHost } from "../../context/HostContext";
import Loading from "../../components/Loading";
import LinearProgressWithCountdown from "../../components/LinearProgressWithCountdown";
import { isKioskRole } from "../../functions";

const ProfileData = ({
  id,
  user,
  showOnly,
  onButtonClick,
  isEditAllowed,
  allResources,
  noHeader,
  noFooter,
  noCloseButton,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { host } = useHost();
  const { setMessage } = useMessage();

  const [profile, setProfile] = useState(null);

  const [isProcessing, setIsProcessing] = useState(false);

  const [loading, setLoading] = useState(false);

  const fetchData = async (searchParams) => {
    setLoading(true);
    setProfile(null);
    setMessage("");

    searchUser(
      { ...searchParams },
      { valid: true, resourcedata: true, transactiondata: false, handlertransactiondata: false }
    ) // , roletype: "attendee"
      .then((response) => {
        if (response.length !== 1) {
          throw new Error(t("invalid-qr-code"));
        }
        return response[0];
      })
      .then((profileData) => {
        if (isKioskRole(host) && host?.id === profileData.user_id) {
          localStorage.removeItem("kiosk");
          localStorage.removeItem("kioskLocation");
          localStorage.removeItem("kioskFacingMode");
          setMessage({ text: "Kioszk védelem kikapcsolva!", type: "warning", duration: 10 * 1000 });
        }

        if (!allResources) {
          return profileData;
        }

        return getUserMissingResources(profileData.user_id).then((missingResources) => {
          profileData.Resources.push(...missingResources);
          return profileData;
        });
      })
      .then((profileData) => {
        setProfile(profileData);
      })
      .catch((error) => {
        setMessage(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isKioskRole(host)) {
      localStorage.setItem("kiosk", "profile");
      localStorage.setItem("kioskFacingMode", "user");
      setMessage({ text: "Kioszk védelem bekapcsolva!", type: "warning", duration: 5 * 1000 });
    }
  }, [host]);

  useEffect(() => {
    if (id) {
      fetchData({ id });
    }
  }, [id]);

  useEffect(() => {
    setProfile(user);
  }, [user]);

  const handleQuantityChange = (data) => {
    setMessage("");
    setIsProcessing(true);

    setUserResourceQuantity(host.id, profile.user_id, data.resourceId, data.quantityChange, "", 1)
      .then((response) => {
        const resourceIndex = profile.Resources.findIndex(
          (resource) => resource.resource_id === parseInt(response.resource_id)
        );

        const newResources = [...profile.Resources];

        if (newResources[resourceIndex]) {
          newResources[resourceIndex].UserResource.quantity_remaining = response.quantity_remaining;

          setProfile({
            ...profile,
            Resources: newResources,
          });
        }
      })
      .catch((error) => {
        if (error?.status === 403) {
          //TODO
        }
        setMessage(error);
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      {!profile && !showOnly && (
        <>
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <MultilineText text={t("profile-title" + (isKioskRole(host) ? "-kiosk" : ""))} />
          </Grid>

          {loading && <Loading />}

          {!loading && (
            <QRScanner
              setResult={(result) => fetchData({ qrcode: result })}
              facingMode={localStorage.getItem("kioskFacingMode")}
            />
          )}
        </>
      )}

      {profile && (
        <Card
          sx={{
            boxShadow: 3,
            border: "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor: "background.paper",
          }}
        >
          {!noHeader && (
            <CardHeader
              title={profile?.user_name}
              titleTypographyProps={{ align: "center" }}
              subheader={isEditAllowed ? t("modify-quantity") : ""}
              subheaderTypographyProps={{ align: "center", color: "white" }}
              sx={{ backgroundColor: "secondary.main", color: "secondary.contrastText" }}
            />
          )}

          {!noFooter && (
            <Typography
              textAlign={"center"}
              sx={{ backgroundColor: "secondary.light", color: "secondary.contrastText", px: 1, py: 1 }}
            >
              {isProcessing ? <Loading></Loading> : profile.Resources.length === 0 ? "" : t("available")}
            </Typography>
          )}

          <CardContent style={{ paddingTop: 0 }}>
            {isEditAllowed && isSmallScreen && (
              <Alert severity={"info"} style={{ marginBottom: 10, marginTop: 0 }}>
                {t("edit-for-double-click")}
              </Alert>
            )}
            <Box
              display="flex"
              flexWrap={"wrap"}
              alignItems={"stretch"}
              justifyContent={"space-evenly"}
              gap={1}
              textAlign={"center"}
              margin={0}
              padding={0}
            >
              {profile.Resources.length > 0 ? (
                profile.Resources.map((resource) => (
                  <Card
                    key={resource.resource_id}
                    variant="outlined"
                    sx={{ minWidth: isEditAllowed ? 200 : 100, boxShadow: 3, marginTop: 1, marginBottom: 1 }}
                  >
                    <CardHeader
                      title={resource.resource_name}
                      sx={{ padding: 1 }}
                      titleTypographyProps={{ variant: "h6", align: "center" }}
                    />
                    <CardContent
                      sx={{
                        position: "relative",
                        padding: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                      }}
                    >
                      {/* 
                      {resource.UserResource.user_resources_quantity_purchased}
                      {resource.UserResource.user_resources_quantity_used}
*/}
                      {isEditAllowed ? (
                        <EditableQuantity
                          resourceId={resource.resource_id}
                          initialQuantity={resource.UserResource.quantity_remaining}
                          onQuantityChange={handleQuantityChange}
                        />
                      ) : (
                        <Typography
                          variant="h5"
                          align="center"
                          sx={{
                            width: "60px",
                            height: "60px",
                            lineHeight: "60px", // Aligns text vertically
                            borderRadius: "50%", // Makes circle shape
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: resource.UserResource.quantity_remaining === 0 ? "red" : "green",
                            userSelect: "none",
                          }}
                        >
                          {resource.UserResource.quantity_remaining}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                ))
              ) : (
                <Typography style={{ userSelect: "none" }}>{t("nodata")}</Typography>
              )}
            </Box>
          </CardContent>
          <CardActions>
            {isEditAllowed && !isSmallScreen && (
              <Alert severity={"info"} style={{ margin: 0 }}>
                {t("edit-for-double-click")}
              </Alert>
            )}
          </CardActions>
        </Card>
      )}

      {profile && !noCloseButton && (
        <>
          <Grid item xs={12} justifyContent="center" alignItems="center" textAlign={"center"} marginTop={2}>
            <Button
              variant="contained"
              onClick={() => {
                setMessage("");
                setProfile(null);
                onButtonClick?.();
              }}
            >
              {t("close")}
            </Button>
          </Grid>

          {isKioskRole(host, "zerohost") && (
            <LinearProgressWithCountdown              
              duration={host?.eventconfig?.zerohost_auto_close_timeout || 10 * 1000}
              text={t("time-to-close")}
              callback={() => {
                setMessage("");
                setProfile(null);
                onButtonClick?.();
              }}
              marginTop={2}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProfileData;
