import React, { useState } from "react";
import { Button, MenuItem, Select, FormControl, InputLabel, Box } from "@mui/material";
import { setUserResourceQuantity, createUser } from "../services/apiService";
import { useHost } from "../context/HostContext";
import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import { useMessage } from "../context/MessageContext";
import { t } from "i18next";

function processData(mappings, data) {
  const result = { user: {}, resources: [] };

  // Iterate through each entry in the data object
  for (const [key, value] of Object.entries(data)) {
    // Check if the key maps to a user attribute
    if (mappings[key].startsWith("user:")) {
      const userField = mappings[key].split(": ")[1];
      result.user[userField] = value;
    } else if (mappings[key].startsWith("resource")) {
      // Parse the resource ID from the mapping
      const resourceId = parseInt(mappings[key].split("_")[1].split(": ")[0]);
      result.resources.push({ resource_id: resourceId, resource_quantity: value });
    }
  }

  // Filter out resources with quantity 0
  result.resources = result.resources.filter((resource) => resource.resource_quantity > 0);

  return result;
}

const DataTableImport = ({ fields, columns, data }) => {
  const { host } = useHost();
  const { setMessage } = useMessage();

  const [importProgress, setImportProgress] = useState(null);

  // State to hold the mappings
  // This will be an object where the key is the column name and the value is the field name
  const [mappings, setMappings] = useState({});

  // Function to handle changing the mapping for a column
  const handleMappingChange = (column, field) => {
    setMappings((prev) => ({
      ...prev,
      [column]: field,
    }));
  };

  // Function to handle the import data action
  const handleImportData = async () => {
    if (data.length === 0) return;

    setImportProgress({ actualValue: 0, totalValue: data.length });

    for (const dataItem of data) {
      try {
        const mappedData = processData(mappings, dataItem);

        const user = mappedData.user;
        const full_name = `${user.user_title || ""} ${user.user_last_name || ""} ${user.user_first_name || ""}`.trim();

        const response = await createUser({
          title: user.user_title || "",
          first_name: user.user_first_name || "",
          last_name: user.user_last_name || "",
          name: full_name,
          email: user.user_email || "",
          company: user.user_company || "",
          roletype: "attendee",
          enabled: true,
        });

        for (const resource of mappedData.resources) {
          try {
            await setUserResourceQuantity(
              host.id,
              response.user_id,
              resource.resource_id,
              resource.resource_quantity,
              "import",
              1
            );
          } catch (error) {
            console.log(error);
          }
        }

        console.log(response.user_id, full_name);
      } catch (error) {
        console.log(error);
      }

      setImportProgress((prev) => ({ ...prev, actualValue: prev.actualValue + 1 }));
    }

    setImportProgress(null);

    setMessage({ text: t(`Importálás befejeződött. (${data.length})`), type: "success" });
  };

  return (
    <Box sx={{ maxWidth: 500, margin: "auto" }}>
      {!importProgress &&
        columns.map((column, index) => (
          <FormControl fullWidth key={index} margin="normal">
            <InputLabel>{column.name}</InputLabel>
            <Select
              value={mappings[column.name] || ""}
              label={column.name}
              onChange={(e) => handleMappingChange(column.name, e.target.value)}
            >
              {fields.map((field, fieldIndex) => (
                <MenuItem key={fieldIndex} value={field}>
                  {field}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      {!importProgress && (
        <Button variant="contained" onClick={handleImportData} sx={{ marginTop: 2 }}>
          {`Import data (${data?.length || 0})`}
        </Button>
      )}

      {importProgress && (
        <CircularProgressWithLabel value={(importProgress.actualValue / importProgress.totalValue) * 100} />
      )}
    </Box>
  );
};

export default DataTableImport;
