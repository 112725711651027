import * as bpac from "./vendor/bpac";
import * as XLSX from "xlsx";

const DATA_FOLDER = `${window.location.origin}${window.location.pathname}`;
const TEMPLATE_NAME = "/QRCode.lbx";

const isPrinterInstalled = () => {
  if (bpac) {
    try {
      const objDoc = new bpac.IDocument();
      if (objDoc) {
        if (bpac.IsExtensionInstalled() == true) {
          return true;
        } else {
          return false;
        }
      }
    } catch (_) {
      return false;
    }
  }
};

const doPrint = async (qrcode, bottomtext, toptext, list, config) => {
  if (!isPrinterInstalled()) return { result: false };

  if (list === undefined) {
    list = [];
    list.push({ objTopText: toptext, objBottomText: bottomtext, objQRCode: qrcode });
  }

  try {
    const strPath = DATA_FOLDER + TEMPLATE_NAME;
    const objDoc = bpac.IDocument;
    const ret = await objDoc.Open(strPath);

    if (ret === true) {
      const objTopText = await objDoc.GetObject("objTopText");
      const objQRCodeText = await objDoc.GetObject("objQRCodeText");
      const objQRCode = await objDoc.GetObject("objQRCode");
      const objBottomText = await objDoc.GetObject("objBottomText");

      list.forEach((element) => {
        // Set values based on template object names
        if (objTopText) {
          const topText = element["objTopText"] || "";
          objTopText.Text = config?.printing_toptext_uppercase ? topText.toUpperCase() : topText;
        }
        if (objQRCodeText) objQRCodeText.Text = element["objQRCode"] || "";
        if (objQRCode)
          objQRCode.Text =
            (config?.attendee_qrcode_prefix || "") +
            (element["objQRCode"] || "") +
            (config?.attendee_qrcode_suffix || "");
        if (objBottomText) {
          const bottomText = element["objBottomText"] || "";
          objBottomText.Text = config?.printing_bottomtext_uppercase ? bottomText.toUpperCase() : bottomText;
        }

        objDoc.StartPrint("", 0);
        objDoc.PrintOut(1, 0);
        objDoc.EndPrint();
      });

      await objDoc.Close();

      return { result: true };
    } else return { result: false };
  } catch (error) {
    return { error };
  }
};

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function downloadCSV(array, filename) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename || "kvikcheck_export.csv");
  link.click();
}

const exportToXlsx = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "KVIKCHECK");
  XLSX.writeFile(workbook, `${fileName || "kvikcheck_export"}.xlsx`);
};

function isURL(str) {
  var urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlPattern.test(str);
}

function getUUID(str) {
  var uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
  return str.match(uuidRegex) || [];
}

function isKiosk() {
  return Boolean(localStorage.getItem("kiosk"));
}

function isKioskRole(host, role) {
  const rolesToCheck = role ? [role] : ["zerohost", "checkin", "checkout"];
  return rolesToCheck.some(r => host?.roles?.includes(r));
}

export { doPrint, downloadCSV, exportToXlsx, isURL, getUUID, isKiosk, isKioskRole};
